import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from '../components/gallery/Container'
const Gallery = () => (
    <Layout>
        <SEO title="Gallery" />
        <Container />
    </Layout>
)

export default Gallery
