import React from 'react'
import Picture from './Picture'
import styled from 'styled-components'
import { SRLWrapper } from "simple-react-lightbox";
const options = {
    buttons: {

        showAutoplayButton: false,
        showDownloadButton: false,
    },
    thumbnails: {
        showThumbnails: false,

    }
}

const Container = () => {
    return (<SRLWrapper options={options}>

        <Grid>
            <Picture />
        </Grid>      </SRLWrapper>

    )
}

export default Container
const Grid = styled.div`
display: grid;
grid-template-columns: 1fr 1fr 1fr;
grid-auto-rows: 1fr;
grid-column-gap: 16px;
grid-row-gap: 16px; 
padding: 16px;
`