
import { graphql, useStaticQuery } from 'gatsby'

import Img from 'gatsby-image'
import React from 'react'

const Picture = () => {
  const ArtImages = useStaticQuery(getImage)
  const images = ArtImages.images.edges

  return (
    <>
      {images.map(image => {
        return <Img fluid={image.node.data.picture.fluid} />
      })}

    </>
  )
}

export default Picture


const getImage = graphql`
	
    query MyQuery {
  images:allPrismicGallery {
    edges {
      node {
        data {
          picture {
            fluid {
                ...GatsbyPrismicImageFluid_noBase64

            }
            alt
          }
        }
      }
    }
  }
}

	
`



